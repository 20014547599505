import React from "react"
import Layout from "../components/layout"
import ContactForm from "../components/contact-form"
import contactStyles from "../components/kontakt.module.css"

export default () => {
  const createWidget = () => {
    return {
      __html: `<a id="zl-url" class="zl-url" href="https://www.znanylekarz.pl/sylwia-osiecka/psycholog-psychoterapeuta-psycholog-dzieciecy/poznan" rel="nofollow" data-zlw-doctor="sylwia-osiecka" data-zlw-type="big" data-zlw-opinion="false" data-zlw-hide-branding="true">Sylwia Osiecka - ZnanyLekarz.pl</a>
      
          <script>!function($_x,_s,id){var js,fjs=$_x.getElementsByTagName(_s)[0];if(!$_x.getElementById(id)){js = $_x.createElement(_s);js.id = id;js.src = "//platform.docplanner.com/js/widget.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","zl-widget-s");</script>`,
    }
  }
  return (
    <Layout>
      <div className={contactStyles.container}>
        <div className={contactStyles.column}>
          <h2>Kontakt</h2>
          <address>
            Sylwia Osiecka
            <br />
            psycholog, psychoterapeuta
            <br />
            <strong>Tel:</strong>&nbsp;600 728 587
            <br />
            <strong>Email:</strong>
            &nbsp;
            <a href="mailto:sylwiaosiecka.psycholog@gmail.com">
              sylwiaosiecka.psycholog@gmail.com
            </a>
            <br/>
            <strong>Facebook: </strong>
            <a href="https://www.facebook.com/Sylwia.Osiecka.psycholog/">Sylwia Osiecka</a>
            <br />
          </address>
          <address>
            Gabinet:
            <br />
            ul. 11 Listopada 60-62 (Kamienica Pod Orłem), Bielsko-Biała
          </address>
          Umów się na spotkanie:<br/>
          <div dangerouslySetInnerHTML={createWidget()} />
        </div>
        <div className={contactStyles.column}>
          <h2>Napisz do mnie</h2>
          <ContactForm />
        </div>
      </div>
      <div style={{ fontSize: "0.7em" }}>
        <hr />
        <p>
          Szanowni Państwo, w związku z wejściem w życie Rozporządzenia
          Parlamentu Europejskiego oraz Rady (UE) 2016/679 z dnia 27 kwietnia
          2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem
          danych osobowych i w sprawie swobodnego przepływu takich danych (RODO)
          oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenia o ochronie
          danych), od dnia 25 maja 2018 roku ma zastosowanie nowe dyrektywy:
        </p>
        <ul>
          <li>
            Podając dane wyrażacie Państwo zgodę na przetwarzanie danych
            osobowych dla potrzeb niezbędnych do realizacji zadań, wynikających
            z przesłanego przez Państwa zapytania.
          </li>
          <li>
            Podanie danych osobowych jest dobrowolne, przysługuje Państwu prawo
            dostępu do podanych danych osobowych i możliwości ich poprawiania,
            uzupełniania, uaktualniania, sprostowania, czasowego lub stałego
            wstrzymania ich przetwarzania lub wycofania zgody i usunięcia danych
            osobowych („bycia zapomnianym”).
          </li>
          <li>
            Państwa dane osobowe będą przetwarzane przez czas niezbędny do
            realizacji w/w celu lub do czasu cofnięcia zgody.
          </li>
          <li>
            Informujemy, że administratorem danych osobowych jest Sylwia Osiecka
            - psycholog. Z administratorem danych osobowych można skontaktować
            się pod adresem:
            <a href="mailto:sylwiaosiecka.psycholog@gmail.com">
              sylwiaosiecka.psycholog@gmail.com
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  )
}
