import React from "react"
import "./contact-form.module.css"
export default () => (
  <form
    name="contact"
    method="POST"
    netlify-honeypot="bot-field"
    data-netlify="true"
    action="/dziekuje/"
  >
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact" />
    <p>
      <label>
        Email<span style={{ color: "red" }}>*</span>:
      </label>
      <br />
      <input type="text" name="email" required />
    </p>
    <p>
      <label>
        Imię i nazwisko:<span style={{ color: "red" }}>*</span>
      </label>
      <br />
      <input type="text" name="name" required />
    </p>
    <p>
      <label>Nr. Telefonu:</label>
      <br />
      <input type="text" name="phone" required />
    </p>
    <p>
      <label>Wiadomość:</label>
      <br />
      <textarea name="message" rows="6" />
    </p>
    <p />
    <p>
      <button type="submit">Wyślij</button>
    </p>
  </form>
)
